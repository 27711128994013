export default function () {

    const $route = useRoute();

    function routeMembership (context) {
        return {
            name: 'membership',
            query: {
                context,
                slug: $route.params.lessonSlug || undefined,
            },
        };
    }

    function routeLogin (context) {
        return {
            name: 'login',
            query: {
                context,
                slug: $route.params.lessonSlug || undefined,
            },
        };
    }

    return {routeMembership, routeLogin};
}
